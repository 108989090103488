import React, { useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { withStyles } from "@material-ui/core/styles"
import { BodyNoticeClient, HeaderNoticeClient } from "./NoticeClient"

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const CustomDialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <>{children}</>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const ModalNotice = () => {
  const [open, setOpen] = useState(true) // modal is off
  const [scroll, setScroll] = React.useState("paper")
  const handleOnOpen = () => {
    setOpen(true)
  }
  const handleOnClose = () => {
    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="customized-dialog-title"
      onClose={handleOnOpen}
      scroll={scroll}
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={handleOnClose}>
        <HeaderNoticeClient />
      </CustomDialogTitle>
      <DialogContent>
        <BodyNoticeClient />
      </DialogContent>
    </Dialog>
  )
}

export { ModalNotice }
