import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import HowItWorks from "../components/HowItWorks"
import Ofrecemos from "../components/Ofecemos"
import OfrecemosSol from "../components/OfrecemosSol"
import Investment from "../components/Investment"
import Home from "../components/Home"
import Finance from "../components/Finance"
import DataVoice from "../components/DataVoice"
import "./index.css"
import { ModalNotice } from "../components/NoticeClient/ModalNotice"

export default function Index() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')"
          rel="stylesheet"
        />
        <title>CFL</title>
        <script>
          {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '468802296204252');
          fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=468802296204252&ev=PageView&noscript=1"
          />`}
        </noscript>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16624227020"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16624227020');`}
        </script>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PZN3P5M');`}
        </script>
      </Helmet>
      <ModalNotice />
      <Home />
      <HowItWorks />
      <Ofrecemos />
      <Investment />
      <OfrecemosSol />
      <Finance />
      <DataVoice />
    </Layout>
  )
}
